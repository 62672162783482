@import "main";

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
    padding-top: 5em;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

.bibliography {
    padding-left: 0;
    list-style: none;
}

pre, code {
    font-size: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    background-color:#eef
}

code {
    padding:1px 5px
}

pre {
    padding: 8px 12px;
    overflow-x:auto
}

pre > code {
    border: 0;
    padding-right: 0;
    padding-left:0
}